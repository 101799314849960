// IMPORTS
@import (once) "/node_modules/swiper/swiper.less";
@import (once) "/node_modules/swiper/modules/navigation.less";
@import (once) "/node_modules/swiper/modules/pagination.less";
@import (once) "/node_modules/swiper/modules/autoplay.less";

@import (once) "settings.less";
 
// GENERAL
body {
    overflow-x: hidden;
    background-color: white;
}

.goshop-modal-content {
    display: none;
}

#container {
    overflow: clip;
    min-height: 100vh;
    min-height: -webkit-fill-available;

    // IMPORTANT
    display: flex;
    flex-direction: column;

    .inner-wrapper > *:not(.inner) {
        width: 100%;
    }

    #footer {
        margin-top: auto;
    }
}

.header-sentinel {
    position: absolute;
    top: 0;
    left: 0;
}

.privacy-sentinel {
    height: 0;
    clear: both;
}

// TOP BAR
.top-bar {
    display: flex;
    justify-content: center;
    padding: 12px 10px;
    background: @header-color;
    color: white;
}

body[data-type="cart"] .top-bar {
    display: none !important;
}

// SHOP HEADER
@media (min-width: 1280px) {
    .shop-header {
        position: sticky;
        top: 0;
        left: 0;
        background-color: white;
        width: 100%;
        transition: box-shadow .2s ease-in-out, transform .2s ease-in-out;
        z-index: 1000;

        &.stuck {
            .shadow();

            .shop-header-container {
                padding: 6px 0;
            }

            .logo {
                transform: scale(0.75);
            }

            .header-tools-wrapper {
                transform: translateY(7px);

                #search-engine {
                    top: 100%;
                }

                > a > em,
                > span > em {
                    transform: translateY(-10px);
                    opacity: 0;
                }
            }
        }
    }

    .logo,
    .shop-header-container,
    .header-tools-wrapper,
    .header-tools-wrapper em {
        transition: all 0.2s ease-in-out;
    }

    .header-tools-wrapper,
    .header-tools-wrapper em {
        transition-delay: 0.2s;
    }

    .header-tools-wrapper em {
        transition:
            0.2s opacity 0.2s ease-in-out,
            0.2s transform 0.2s ease-in-out,
            0s color 0s ease-in-out;
    }
}

@media (min-width: 1681px) {
    .shop-header + * {
        transition: margin 0.2s ease-in-out;
    }

    body:has(.shop-header.stuck) {
        .shop-header + * {
            margin-top: 36px;
        }
    }
}

.header-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    position: relative;
    padding: 12px 0;

    &:empty {
        display: none;
    }

    &:before {
        .full-bg();
        background: @sec-color;
    }
}

.header-shipment,
.header-contact .phone,
.header-contact .email {
    display: flex;
    align-items: center;
    gap: 10px;
    color: @header-color;

    &:before {
        .small-icon();
    }
}

.header-shipment {
    margin-right: auto;

    &:before {
        content: "\e90f";
    }

    b {
        margin-left: -6px;
    }
}

.header-contact {
    display: flex;
    gap: 58px;
    padding-left: 28px;

     a {
        position: relative;


        &:before {
            font-family: icomoon;
            position: absolute;
            font-size: 20px;
            top: 50%;
            transform: translateY(-50%);
            left: -34px;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .phone:before {
        content: "\e91c";
    }

    .email:before {
        content: "\e91b";
    }
}

.header-social-links {
    margin-left: 16px;
    display: flex;
    gap: 10px;

    > a:before {
        .small-icon();
        color: @header-color;
        font-size: 24px;
    }

    .facebook-link:before { 
        content: "\e91a";
    }

    .instagram-link:before {
        content: "\e919";
    }

    >a:hover:before {
        color: @error-color;
    }
}

.shop-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0;
}

.logo {
    height: 75px;
    aspect-ratio: ~"143/75";

    img {
        .img-contain();
    }
}

// MAIN BAR
@media (min-width: 1280px) {
    .cms-rwd {
        display: none;
    }

    .tree-top small {
        display: none;
    }

    #main-bar {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 32px;
        font-weight: 700;
    }

    .mainmenu,
    #tree {
        display: flex;
        align-items: center;
        gap: 32px;

        a {
            color: @header-color;
            white-space: nowrap;
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: space-between;

            &:hover {
                color: @error-color;
            }
        }

        .tree-top > ul > li > a, // controls main bar height
        > li > a {
            padding: 12px 0;
        }
    }

    .mainmenu a {
        font-weight: normal;
    }

    .tree-top,
    .tree-top > ul:not(.cms-rwd) {
        display: contents;
    }

    body:has(header.stuck) .tree-top .t0 {

        > ul {
            top: calc(100% + 18px);

            &:before {
                height: 20px !important;
                top: -20px !important;
            }
        }
    }

    // TREE
    .tree-top > ul:not(.cms-rwd) {
        position: relative;

        small {
            display: none;
        }

        .parent > a:after { // arrows
            .small-icon();
            content: "\e910";
            font-size: 12px;
            position: relative;
        }

        .t0 { // main bar visible list
            z-index: 100;
            display: flex;
            align-items: center;

            &:hover {

                > a:after {
                    transform: scaleY(-1);
                }

                > ul:before {
                    content: '';
                    background: transparent;
                    width: 100%;
                    height: 38px;
                    position: absolute;
                    top: -36px;
                    left: 0;
                }
            }
        }

        li { // both nested lists
            position: relative;

            ul {
                position: absolute;
                background-color: white;
                min-width: 250px;
                padding: 0;
                transition: all .2s ease-in-out;
                transition-delay: 0.1s;
                opacity: 0;
                visibility: hidden;

                a {
                    padding: 12px 16px;

                    &:after {
                        transform: rotate(-90deg);
                    }
                }
            }

            &:hover {

                > ul {
                    opacity: 1;
                    visibility: visible;
                    transform: none !important;
                }

                > a {
                    color: @error-color !important;
                }
            }
        }

        .t0 > ul { // first nested list
            top: calc(100% + 37px);
            left: -16px;
            transform: translateY(-10px); // for animation
            border-radius: 0 0 8px 8px;

            &:before { // redundancy for hover
                position: absolute;
                content: '';
                background: transparent;
                display: block;
                height: 8px;
                width: 100%;
                left: 0;
                top: -4px;
            }
        }

        li:not(.t0) > ul { // side nested lists
            left: calc(100%);
            top: 0;
            transform: translateX(-10px); // for animation
            border-radius: 8px;

            &:before { // redundancy for hover
                position: absolute;
                content: '';
                background: transparent;
                display: block;
                height: 100%;
                width: 8px;
                top: 0;
                left: -4px;
            }
        }
    }
}

// HEADER TOOLS WRAPPER
.header-tools-wrapper {
    display: flex;
    gap: 38px;
    position: relative;
    z-index: 100; // for things to not pop up from below #search-engine on rwd

    &:before {
        content: "";
        background: transparent;
        position: absolute;
        inset: -14px -20px;
    }
}

#cart-box,
#wishlist-box,
.log-button,
.search-engine-trigger,
.tree-trigger {
    display: block;
    cursor: pointer;
    position: relative;
    padding-top: 34px;

    &:before {
        font-family: icomoon;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        font-size: 32px;
        line-height: 1;
        color: @header-color;
    }

    em {
        color: rgba(32,33,36,0.6);
        font-style: normal;
        text-transform: uppercase;
        font-size: 12px;
        display: block;
    }


    @media (min-width: 1280px) {
        &:hover {

            em,
            &:before {
                color: @error-color;
            }
        }
    }
}

.search-engine-trigger {

    &:before {
        content: "\e918";
    }
}

.log-button {

    &:before {
        content: "\e917";
    }
}

#wishlist-box {

    &:before {
        content: "\e916";
    }

    &:after {
        right: 1px !important;
    }

    &[data-wishlist-count="0"]:after {
        display: none;
    }
}

#cart-box {

    &:before {
        content: "\e915";
    }
}

#wishlist-box:after,
#cart-box i {
    font-style: normal;
    font-size: 11px;
    font-weight: 700;
    content: attr(data-wishlist-count);
    color: black;
    position: absolute;
    top: -6px;
    right: -3px;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @main-color;
    border-radius: 50%;
}

@media (min-width: 1280px) {
    .tree-trigger,
    .tree-canvas {
        display: none;
    }
}

// SEARCH ENGINE
@media (min-width: 1280px) {
    #suggestions {
        right: -3px !important;
        z-index: -1;
    }

    #search-engine {
        background: transparent;
        position: absolute;
        right: 0;
        top: calc(100% + 12px);
        width: 320px;
        z-index: 100;
        transition: all 0.2s ease-in-out 0.1s;
        opacity: 0;
        visibility: hidden;
        align-items: center;
        transform: translateX(10px);

        &:hover {
            transition-delay: 0s;
            transition: all 0.1s ease-in-out;
        }

        &.show,
        &.show-last-phrases {

            #suggestions {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                transition: all .2s ease-in-out;
            }
        }

        &:not(.show-last-phrases) {

            .suggestion-last-phrases {
                display: none;
            }

            &:has(.suggestion-result-products:empty) {

                .no-products-info {
                    display: block !important;
                }
            }
        }

        input[type="text"] {
            height: 48px;
            padding: 0 50px 0 22px;
            background-color: white;
            color: @header-color;
            font-size: 15px;
            font-family: @main-font-family;
            border: 1px solid @border-color;
            width: 320px;

            &::-webkit-input-placeholder {
                color: @font-color;
            }

            &::-moz-placeholder {
                color: @font-color;
            }

            &:-ms-input-placeholder {
                color: @font-color;
            }

            &:-moz-placeholder {
                color: @font-color;
            }
        }

        #searchbutton {
            position: absolute;
            top: -67px;
            left: 15px;
            width: 56px;
            height: 56px;
            cursor: pointer;
            opacity: 0;
            display: flex;
        }

        .search-engine-close {
            font-size: 0;
            width: 32px;
            aspect-ratio: 1;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 8px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            &:after {
                content: "\e803";
                .small-icon();
                font-size: 24px;
            }
        }
    }

    body.show-search-engine {
        .search-engine-trigger:before,
        .search-engine-trigger em {
            color: @error-color !important;
        }

        #search-engine {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
        }
    }
}

// SUGGESTIONS
#suggestions {
    position: absolute;
    opacity: 0;
    visibility: hidden;
}

// DECAL
.decal {
    position: absolute;
    top: calc(100% - 1px);
    left: 50%;
    transform: translateX(-50%);
    height: 108px;
    border-top: 8px solid @main-color;
    position: absolute;
    bottom: 0;
    aspect-ratio: ~"1920/108";
    pointer-events: none;
    user-select: none;
    background-size: contain;
    background-repeat: repeat-x;
    max-width: 101vw;

    @media (max-width: 1279px) {
        aspect-ratio: initial;
        width: 101vw;
        height: 54px;
        border-top-width: 6px;
    }
}

//non-lazyloaded ver
#breadcrumb,
.main-banner-wrapper {

    .decal {
        background-image: url("../assets/images/decal.jpg");
        /*background: url("../assets/images/decal.jpg");*/
    }
}

//non-lazyloaded ver
body[data-type="listing"] #breadcrumb .decal { // with listing sec color bg
    background: url("../assets/images/decal-alt-bg.jpg");
}

// BREADCRUMB
#breadcrumb {
    padding: 21px 0 20px;
    position: relative;
    margin-bottom: calc(-0.5 * var(--rowGap) + 108px);
    position: relative;
    background: white;
    z-index: 1;

    .bg-anchor {
        position: absolute;
        height: 100%;
        width: 100%;
        bottom: 0;
        left: 0;
        pointer-events: none;

        &:before {
            .hr-after();
            top: 0;
            height: 1px;
            background-color: @main-color;
            z-index: 0;
        }

        &:after {
            .full-bg();
            background: white;
            bottom: 0;
        }
    }

    ul {
        display: flex;
        flex-wrap: wrap;
    }

    li {
        display: flex;

        &:after {
            content: '-';
            margin: 0 6px;
        }

        &:last-child {
            font-weight: bold;

            &:after {
                display: none;
            }
        }
    }

    a,
    span {
        color: @font-color;
        display: block;
    }
}

[data-type="mainpage"] #breadcrumb {
    display: none;
}
